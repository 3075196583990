import React, {useContext} from "react";
import {AppContext} from "./AppContext";
import {AppMenu} from "./AppMenu";
import {AppMain} from "./AppMain";
import {AppTutorial} from "./AppTutorial";
import {Login} from "../login/Login";

export function App() {
    const appContext = useContext(AppContext);

    if (appContext == null) {
        return (<div/>)
    } else if (appContext?.currentPerson == null) {
        return (<Login/>)
    } else {
        return (
            <div className="app">
                <AppMenu/>
                <div className="content">
                    {appContext.currentPersonSettings
                        && appContext.currentPersonSettings.disableTutorial !== true
                        && <AppTutorial/>}
                    <AppMain/>
                </div>
            </div>
        )
    }
}