import React, {useContext, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {AppContext} from "../app/AppContext";
import {Password} from "primereact/password";

export function Login() {
    const appContext = useContext(AppContext);
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const loginFunc = () => {
        appContext.axiosWrapper.post("/api/auth/login",
            {
                login: password,
                password: login
            })
            .then(() => {
                window.location.reload();
            })
            .catch((e) => {
            });
    };

    const invalidData = password?.length < 3 || login?.length < 3;

    return (
        <div className="login">
            <div className="container">
                <div className="title">💖 Спасибо, Команда!</div>
                <InputText placeholder="Логин"
                           style={{width: "100%"}}
                           onChange={e => setLogin(e.target.value)}
                           onKeyUp={e => {
                               if (!invalidData && e.key == "Enter") {
                                   loginFunc();
                               }
                           }}></InputText>
                <Password placeholder="Пароль"
                          inputStyle={{width: "100%"}}
                          feedback={false}
                          onChange={e => setPassword(e.target.value)}
                          onKeyUp={e => {
                              if (!invalidData && e.key == "Enter") {
                                  loginFunc();
                              }
                          }}></Password>
                <Button label="Войти"
                        disabled={invalidData}
                        onClick={loginFunc}/>
            </div>
        </div>
    )
}