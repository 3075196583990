import React, {useContext, useState} from "react";
import {Button} from "primereact/button";
import {AppContext} from "../app/AppContext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {Rating} from "primereact/rating";
import {useQueryClient} from "react-query";

class FeedbackProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export function Feedback(props: FeedbackProps) {
    const appContext = useContext(AppContext);
    const queryClient = useQueryClient();

    const [text, setText] = useState("Пожалуйста, добавьте больше крутых штук!");
    const [uiRating, setUiRating] = useState(3);
    const [profileRating, setProfileRating] = useState(3);
    const [searchRating, setSearchRating] = useState(3);
    const [sendThanksRating, setSendThanksRating] = useState(3);
    const [overallRating, setOverallRating] = useState(3);

    return (
        <Dialog header="Спасибо за использование приложения 'Спасибо, Команда!'"
                visible={props.visible}
                style={{width: '40vw'}}
                onHide={() => {
                    if (!props.visible) return;
                    props.setVisible(false);
                }}>
            <div className="feedback">
                <h2>Мы будем рады получить ваш отзыв!</h2>
                <div className="entry">Считаете ли вы, что интерфейс удобен в использовании?
                    <Rating value={uiRating} onChange={(e) => setUiRating(e.value)} cancel={false} stars={6}/>
                </div>
                <div className="entry">Содержат ли профили пользователей всю необходимую информацию?
                    <Rating value={profileRating} onChange={(e) => setProfileRating(e.value)} cancel={false} stars={6}/>
                </div>
                <div className="entry">Насколько сложно найти то, что вы ищете?
                    <Rating value={searchRating} onChange={(e) => setSearchRating(e.value)} cancel={false} stars={6}/>
                </div>
                <div className="entry">Процесс отправки благодарности достаточно простой и удобный?
                    <Rating value={sendThanksRating} onChange={(e) => setSendThanksRating(e.value)} cancel={false} stars={6}/>
                </div>
                <div className="entry">Каково ваше общее мнение о веб-приложении? Будете ли вы сами им пользоваться и рекомендовать коллегам?
                    <Rating value={overallRating} onChange={(e) => setOverallRating(e.value)} cancel={false} stars={6}/>
                </div>
                <div className="entry">Может быть, есть что-то еще, что вы хотели бы увидеть или исправить?
                    <InputTextarea value={text}
                                   style={{width: "100%"}}
                                   onChange={(e) => setText(e.target.value)}
                                   rows={3}/>
                </div>
                <Button label="Оставить отзыв"
                        disabled={text?.length < 10}
                        onClick={() => appContext.axiosWrapper.post(
                            "/api/feedback",
                            {text, uiRating, profileRating, searchRating, sendThanksRating, overallRating})
                            .then(() => {
                                appContext.toast.current.show({
                                    severity: 'success',
                                    summary: 'Успешно',
                                    detail: 'Отправлено!',
                                    life: 5000
                                });
                                queryClient.invalidateQueries("/api/feedback")
                                setTimeout(() => {
                                    props.setVisible(false);
                                }, 1000)
                            })}/>
            </div>
        </Dialog>
    )
}