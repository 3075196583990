import React, {useContext} from "react";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {Avatar} from "primereact/avatar";
import {Button} from "primereact/button";
import {AppContext} from "../app/AppContext";

interface PersonDivProps {
    id: string
}

export function PersonDiv(props: PersonDivProps) {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const queryPersonPath = `/api/person/${props?.id}`
    const queryPerson = useQuery(
        [queryPersonPath],
        async () => (await appContext.axiosWrapper.get(queryPersonPath)).data,
        {
            enabled: appContext.currentPerson != null && props?.id != null,
            staleTime: 30000,
            refetchOnWindowFocus: false
        });
    const person = queryPerson?.data as Person;

    return (
        <Button outlined severity="secondary" className="view-person" onClick={() => navigate(`/profile/${props?.id}`)}>
            <Avatar label={person?.fullName?.at(0)} size="normal" shape="circle"/>
            <div className="name">{person?.fullName}</div>
            <div className="position">({person?.position})</div>
        </Button>
    )
}