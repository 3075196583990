import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {useQuery} from "react-query";
import {Toast} from "primereact/toast";
import axios, {AxiosInstance} from "axios";
import {Feedback} from "../feedback/Feedback";
import {isToday} from "date-fns";
import {Simulate} from "react-dom/test-utils";


const axiosWrapper = axios.create();

export const AppContext: React.Context<AppContextType>
    = React.createContext(null);

export type AppContextType = {
    axiosWrapper: AxiosInstance;
    currentPerson: Person;
    currentPersonSettings: PersonSettings;
    toast: MutableRefObject<Toast>;
    showFeedback: Function;
}

export const AppContextProvider = ({children}: any) => {
    const loggedIn = useRef<boolean>(null)
    const toast = useRef<Toast>(null)
    const [feedbackVisible, setFeedbackVisible] = useState(false);

    useEffect(() => {
        axiosWrapper.interceptors.response.clear();
        axiosWrapper.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                if (loggedIn.current) {
                    toast?.current?.show({
                        severity: 'error',
                        summary: `Error ${error.response.status}`,
                        detail: `${error.response.data}`,
                        life: 5000
                    });
                }
                return Promise.reject({...error});
            }
        );
    }, [toast, loggedIn]);

    const currentPersonPath = '/api/person/me';
    const currentPersonQuery = useQuery(
        currentPersonPath,
        async () => {
            return (await axios.get(currentPersonPath)
                .then((answer) => {
                    if (loggedIn.current === true && answer?.status == 403) {
                        window.location.reload();
                    }
                    return answer?.data;
                })
                .catch((answer) => {
                    if (loggedIn.current === true && answer?.status == 403) {
                        window.location.reload();
                    }
                    throw answer;
                }));
        },
        {
            enabled: loggedIn.current == null || loggedIn.current === true,
            staleTime: 30000,
            cacheTime: 30000,
            retry: loggedIn.current === false,
            retryDelay: 10000
        });
    const currentPerson = currentPersonQuery?.data as Person;

    loggedIn.current = currentPersonQuery.isSuccess;

    const settingsPath = '/api/settings';
    const settingsQuery = useQuery(
        settingsPath,
        async () => (await axiosWrapper.get(settingsPath)).data,
        {
            enabled: currentPerson != null,
            staleTime: Infinity
        });
    const currentPersonSettings = settingsQuery?.data as PersonSettings;

    const feedbackQueryPath = '/api/feedback';
    const feedbackQuery = useQuery(
        feedbackQueryPath,
        async () => (await axios.get(feedbackQueryPath)).data,
        {
            enabled: currentPerson != null,
            staleTime: Infinity
        });
    const lastFeedbackDate = new Date((feedbackQuery?.data as string) ?? "1970-01-01");
    const feedbackSentToday = isToday(lastFeedbackDate);

    useEffect(() => {
        if (!feedbackSentToday) {
            const timeoutId = setTimeout(() => {
                setFeedbackVisible(true);
            }, 10 * 60 * 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [feedbackSentToday]);

    return (
        <AppContext.Provider value={{
            axiosWrapper,
            currentPerson,
            currentPersonSettings,
            toast,
            showFeedback: () => setFeedbackVisible(true),
        }}>
            {children}
            <Feedback visible={feedbackVisible} setVisible={setFeedbackVisible}/>
            <Toast ref={toast}/>
        </AppContext.Provider>
    );
};