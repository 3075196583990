import React, {useContext} from "react";
import {Dropdown} from "primereact/dropdown";
import {AppContext} from "../app/AppContext";
import {useQuery} from "react-query";

class SendCommendBadgeDropdownProps {
    commendValue: CommendValue;
    setCommendValue: React.Dispatch<React.SetStateAction<CommendValue>>;
    disabled: boolean;
}

export function SendCommendBadgeDropdown(props: SendCommendBadgeDropdownProps) {
    const appContext = useContext(AppContext);

    const valuesPath = '/api/commends/values';
    const valuesQuery = useQuery(
        valuesPath,
        async () => (await appContext.axiosWrapper.get(valuesPath)).data,
        {
            enabled: appContext.currentPerson != null,
            staleTime: Infinity
        });
    const commendValues = valuesQuery?.data as Array<CommendValue>;

    return (
        <div className="item">
            <label htmlFor="badge">Значок</label>
            <Dropdown
                id="badge"
                value={props.commendValue}
                onChange={(e) => props.setCommendValue(e.value)}
                options={commendValues}
                optionLabel="name"
                itemTemplate={(option) => {
                    return <div><b>{option?.icon} {option?.name}:</b> {option?.description}</div>;
                }}
                placeholder="Выбери значок"
                valueTemplate={(option: CommendValue) => {
                    if (!option) {
                        return <div>Выбери значок</div>;
                    }
                    return <div><b>{option?.icon} {option?.name}:</b> {option?.description}</div>;
                }}
                disabled={props.disabled}
                className=""/>
        </div>
    )
}