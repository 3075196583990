import React from "react";

import {Route, Routes} from "react-router-dom";
import {Profile} from "../profile/Profile";
import {Public} from "../public/Public";
import {GroupProfile} from "../group/GroupProfile";
import {Search} from "../search/Search";

export function AppMain() {
    return (
        <div className="app-main">
            <Routes>
                <Route path="/" element={<Public/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/profile">
                    <Route path="" element={<Profile/>}/>
                    <Route path="me" element={<Profile/>}/>
                    <Route path=":id" element={<Profile/>}/>
                </Route>
                <Route path="/group">
                    <Route path=":id" element={<GroupProfile/>}/>
                </Route>
            </Routes>
        </div>
    )
}