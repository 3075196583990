import React, {useContext} from "react";
import {useQuery} from "react-query";
import {AppContext} from "../app/AppContext";
import {Panel} from "primereact/panel";
import {Feed} from "../feed/Feed";
import {CommendValueDiv} from "../view/CommendValueDiv";

interface ProfileCommendsProps {
    id: string
}

export function ProfileCommends(props: ProfileCommendsProps) {
    const appContext = useContext(AppContext);
    const queryPath = `/api/profile/data/${props.id}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props.id != null
        });
    const response = (query?.data ?? {}) as GetPersonProfileResponse;

    const isMyProfile = props.id === appContext?.currentPerson?.id;
    const empty = response?.commends && response.commends.total == 0;
    const total = response?.commends?.total;

    return (
        <div className="profile-commends">
            {empty &&
                <Panel header="Ой...">
                    <div className="profile-commends-empty">
                        <h2>
                            {isMyProfile && "😿 Никто ещё не отправил тебе Спасибо..."}
                            {!isMyProfile && "😿 Никто ещё не отправил твоему коллеге Спасибо!"}
                        </h2>
                        {isMyProfile && <p>Не стесняйся попросить кого-то написать тебе Спасибо! 💌️</p>}
                        {isMyProfile && <p>Еще лучше — отправь Спасибо кому-нибудь, и они наверняка ответят тебе тем же! 💘️</p>}
                        {!isMyProfile && <p>Будь первым, кто оставит благодарность коллеге! 💌️</p>}
                    </div>
                </Panel>}
            {!empty && <Panel header="Статистика">
                <div className="profile-stats">
                    <div className="total">Всего значков: {total}</div>
                    <div className="types">
                        {response?.commends?.byType?.map((c: CountCommendsByTypeProjection) =>
                            <CommendValueDiv key={c.valueId} id={c.valueId} count={c.count}/>)}
                    </div>
                </div>
            </Panel>}
            {!empty && <Feed id={props.id} type="person"/>}
        </div>
    )
}