import React, {useContext} from "react";
import {useQuery} from "react-query";
import {PersonGroupDiv} from "../view/PersonGroupDiv";
import {AppContext} from "../app/AppContext";

interface ProfileDetailsGroupsProps {
    id: string
}

export function ProfileGroups(props: ProfileDetailsGroupsProps) {
    const appContext = useContext(AppContext);
    const queryPath = `/api/group/person/${props.id}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props.id != null
        });
    const groupIds = (query?.data ?? []) as Array<string>;

    return (
        <div className="profile-groups">
            {groupIds.map((id) => (<PersonGroupDiv key={id} id={id}/>))}
        </div>
    )
}