import React from "react";
import {Feed} from "../feed/Feed";
import {Suggestions} from "../suggestions/Suggestions";
import {Leaderboard, LeaderboardPeriod} from "./leaderboard/Leaderboard";

export function Public() {
    return (
        <div className="public">
            <Suggestions/>
            <Feed/>
            <Leaderboard period={LeaderboardPeriod.MONTH}/>
        </div>
    )
}