import React, {useContext, useState} from "react";
import {Column} from "primereact/column";
import {DataTable, DataTableFilterMeta, DataTableFilterMetaData} from "primereact/datatable";
import {FilterMatchMode} from "primereact/api";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../app/AppContext";

const filtersDefault: DataTableFilterMeta = {
    fullName: {value: null, matchMode: FilterMatchMode.CONTAINS},
};

export function SearchPersonTable() {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const [filters, setFilters] = useState(filtersDefault);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);

    const fullName: string = ((filters?.fullName) as DataTableFilterMetaData).value
    const queryPath = `/api/person/search?filter=${fullName}&page=${page}`;
    const query = useQuery(
        [queryPath],
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && fullName?.length > 0
        });
    const result: QueryResponse<Person> = query?.data as QueryResponse<Person>;

    return (
        <DataTable value={result?.data}
                   filters={filters}
                   onFilter={(e) => setFilters(e.filters)}
                   loading={query?.isLoading}
                   selectionMode="single"
                   paginator
                   lazy
                   rows={10}
                   emptyMessage="Ничего не найдено! Попробуй поменять фильтры."
                   first={first}
                   totalRecords={result?.queryPageResponse?.totalElements}
                   onPage={(page) => setPage(page.page)}
                   onRowClick={(e) => navigate(`/profile/${((e.data) as Person).id}`)}>
            <Column field="fullName"
                    filter
                    filterField="fullName"
                    showFilterMatchModes={false}
                    header="Имя"/>
            <Column field="position"
                    header="Должность"/>
            <Column field="group"
                    header="Отдел"/>
        </DataTable>
    )
}