import React, {useContext} from "react";
import {useQuery} from "react-query";
import {Panel} from "primereact/panel";
import {PersonDiv} from "../view/PersonDiv";
import {PersonGroupDiv} from "../view/PersonGroupDiv";
import {AppContext} from "../app/AppContext";

export function Suggestions() {
    const appContext = useContext(AppContext);
    const queryPath = `/api/suggestions`
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null
        });
    const response: GetSuggestionsResponse = query?.data as GetSuggestionsResponse;

    return (
        <Panel header="Предложения">
            {response != null &&
                <div className="suggestions">
                    {response?.randomGroup &&
                        <div className="item">
                            <p>Посмотри профиль одного из твоих отделов:</p>
                            <div className="link"><PersonGroupDiv id={response.randomGroup}/></div>
                        </div>}
                    {response?.randomTeammate &&
                        <div className="item">
                            <p>Отправь Спасибо одному из твоих коллег:</p>
                            <div className="link"><PersonDiv id={response.randomTeammate}/></div>
                        </div>}
                    {response?.lastSender &&
                        <div className="item">
                            <p>Посмотри, кто отправил тебе Спасибо последним:</p>
                            <div className="link"><PersonDiv id={response.lastSender}/></div>
                        </div>}
                </div>}
        </Panel>
    )
}