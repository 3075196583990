import React, {useContext, useState} from "react";
import {useQuery} from "react-query";
import {Panel} from "primereact/panel";
import {PersonDiv} from "../view/PersonDiv";
import {Paginator} from "primereact/paginator";
import {AppContext} from "../app/AppContext";

interface GroupProfileMembersProps {
    id: string
}

export function GroupProfileMembers(props: GroupProfileMembersProps) {
    const appContext = useContext(AppContext);
    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);

    const queryPath = `/api/group/members/${props.id}/${page}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props.id != null
        });
    const response: QueryResponse<string> = (query?.data ?? []) as QueryResponse<string>;

    return (
        <Panel header="Коллеги">
            <div className="group-members">
                <h3>Всего в отделе: {response?.queryPageResponse?.totalElements}</h3>
                <div className="list">
                    {response?.data?.map(id => (<PersonDiv key={id} id={id}/>))}
                </div>
                {response?.queryPageResponse?.totalPages > 1 &&
                    <div className="paginator">
                        <Paginator first={first}
                                   rows={10}
                                   totalRecords={response?.queryPageResponse?.totalElements}
                                   onPageChange={(e) => {
                                       setPage(e.page);
                                       setFirst(e.first);
                                   }}/>
                    </div>}
            </div>
        </Panel>
    )
}