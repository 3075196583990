import {createRoot} from "react-dom/client";

import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";

import './style.less'
import {BrowserRouter} from "react-router-dom";
import {AppContextProvider} from "./app/AppContext";
import {App} from "./app/App";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {staleTime: 30000, retry: false}
    }
});
createRoot(document.getElementById("root")).render(
    <QueryClientProvider client={queryClient}>
        <AppContextProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </AppContextProvider>
    </QueryClientProvider>
);