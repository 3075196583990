import React, {useContext} from "react";
import {useQuery} from "react-query";
import {Panel} from "primereact/panel";
import {AppContext} from "../app/AppContext";

interface GroupProfileDetailsProps {
    id: string
}

export function GroupProfileDetails(props: GroupProfileDetailsProps) {
    const appContext = useContext(AppContext);
    const queryPath = `/api/group/${props.id}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props.id != null
        });
    const response = (query?.data ?? {}) as PersonGroup;

    return (
        <Panel header="Профиль отдела">
            <div className="profile-details">
                <div className="name">{response?.name}</div>
            </div>
        </Panel>
    )
}