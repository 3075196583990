import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {AppContext} from "./AppContext";

export function AppMenu() {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    return (
        <div className="topbar">
            <Button outlined severity="secondary"
                    label="💖 Спасибо, Команда!"
                    style={{margin: "0 1em"}}
                    onClick={() => navigate("/")}/>
            <Button outlined severity="secondary"
                    icon="pi pi-user" label="Профиль"
                    onClick={() => navigate("/profile/me")}/>
            <Button outlined severity="secondary"
                    icon="pi pi-search" label="Поиск"
                    onClick={() => navigate("/search")}/>
            <Button outlined severity="secondary"
                    icon="pi pi-exclamation-circle" label="Отзыв"
                    onClick={() => appContext.showFeedback()}/>
        </div>
    )
}