import React from "react";
import {PersonDiv} from "../../view/PersonDiv";

export enum LeaderboardPeriod {
    WEEK = "week",
    MONTH = "month",
    YEAR = "year"
}

interface LeaderboardRowProps {
    row: CountCommendsByPersonView
}

export function LeaderboardRow(props: LeaderboardRowProps) {
    return (
        <div className="item">
            <PersonDiv id={props.row.personId}/>
            <div className="subtitle">{props?.row?.count}</div>
        </div>
    )
}