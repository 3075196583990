import React, {useContext, useState} from "react";
import {useQuery} from "react-query";
import {Paginator} from "primereact/paginator";
import {FeedItem} from "./FeedItem";
import {FeedPublicItem} from "./FeedPublicItem";
import {Panel} from "primereact/panel";
import {AppContext} from "../app/AppContext";

interface FeedProps {
    id?: string,
    type?: string
}

export function Feed(props: FeedProps) {
    const appContext = useContext(AppContext);

    const [first, setFirst] = useState(0);
    const [page, setPage] = useState(0);

    const isPublic = props.id == null || props.type == null;

    const queryPath = isPublic
        ? `/api/feed/public/${page}`
        : `/api/feed/${props.type}/${props.id}/${page}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null
        });
    const response: QueryResponse<Commend> = (query?.data ?? []) as QueryResponse<Commend>;
    const showReceiver = isPublic || props?.type != 'person'

    if (response != null && (response.data == null || response.data.length === 0)) {
        return <Panel header="Лента"><div className="feed">О нет! Эта лента сообщений пустая...</div></Panel>;
    }

    return (
        <Panel header="Лента">
            <div className="feed">
                {response?.data?.map((item) => {
                    if (showReceiver) {
                        return (<FeedPublicItem key={item.id} commend={item}/>);
                    }
                    return (<FeedItem key={item.id} commend={item}/>);
                })}
                {response?.queryPageResponse?.totalPages > 1 &&
                    <Paginator first={first}
                               rows={5}
                               totalRecords={response?.queryPageResponse?.totalElements}
                               onPageChange={(e) => {
                                   setPage(e.page);
                                   setFirst(e.first);
                               }}/>}
            </div>
        </Panel>
    )
}