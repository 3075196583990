import React, {useContext} from "react";
import {useQuery} from "react-query";
import {LeaderboardRow} from "./LeaderboardRow";
import {AppContext} from "../../app/AppContext";
import {Panel} from "primereact/panel";

export enum LeaderboardPeriod {
    WEEK = "week",
    MONTH = "month",
    YEAR = "year"
}

interface LeaderboardProps {
    period: LeaderboardPeriod
}

export function Leaderboard(props: LeaderboardProps) {
    const appContext = useContext(AppContext);
    const queryPath = `/api/leaderboard/${props.period.toString()}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data);
    const response = (query?.data ?? {}) as GetLeaderboardResponse;

    return (
        <Panel header="Таблица лидеров: месяц">
            <div className="leaderboard">
                <div className="table">
                    <div className="title">Получено</div>
                    {response?.received?.map(p => <LeaderboardRow key={p.personId} row={p}/>)}
                </div>
                <div className="table">
                    <div className="title">Отправлено</div>
                    {response?.sent?.map(p => <LeaderboardRow key={p.personId} row={p}/>)}
                </div>
            </div>
        </Panel>
    )
}