import React, {useContext} from "react";
import {useQuery} from "react-query";
import {useNavigate} from "react-router-dom";
import {Avatar} from "primereact/avatar";
import {Button} from "primereact/button";
import {AppContext} from "../app/AppContext";

interface PersonGroupDivProps {
    id: string
}

export function PersonGroupDiv(props: PersonGroupDivProps) {
    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const queryPath = `/api/group/${props?.id}`
    const query = useQuery(
        [queryPath],
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props?.id != null,
            staleTime: 30000,
            refetchOnWindowFocus: false
        });
    const group = query?.data as PersonGroup;

    return (
        <Button outlined
                severity="secondary"
                className="view-persongroup"
                onClick={() => navigate(`/group/${props?.id}`)}>
            <Avatar label={group?.name?.at(0)} size="normal" shape="circle"/>
            <div className="name">{group?.name}</div>
        </Button>
    )
}