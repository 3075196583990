import React from "react";
import {formatDateString} from "../api/Dates";
import {PersonDiv} from "../view/PersonDiv";
import {CommendValueDiv} from "../view/CommendValueDiv";
import {Divider} from "primereact/divider";

interface FeedPublicItemProps {
    commend: Commend
}

export function FeedPublicItem(props: FeedPublicItemProps) {
    return (
        <div className="item">
            <div className="top">
                <div className="title">
                    <div className="subtitle">
                        <div>{formatDateString(props?.commend?.createdAt)}</div>
                    </div>
                    <div className="sender">От <PersonDiv id={props.commend.senderId}/></div>
                    <div className="receiver">Для <PersonDiv id={props.commend.receiverId}/></div>
                </div>
            </div>
            <Divider/>
            <div className="body">
                <div className="topic">
                    <CommendValueDiv id={props.commend.valueId}/>
                    <div>{props.commend.topic}</div>
                </div>
                {props.commend.comment &&
                    <div className="comment">{props.commend.comment}</div>
                }
            </div>
        </div>
    )
}