import React, {useContext, useRef, useState} from "react";
import {Button} from "primereact/button";
import {useParams} from "react-router-dom";
import {AppContext} from "../app/AppContext";
import {InputTextarea} from "primereact/inputtextarea";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {SendCommendBadgeDropdown} from "./SendCommendBadgeDropdown";

export function SendCommend() {
    const {id} = useParams();
    const appContext = useContext(AppContext);
    const ref = useRef(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [commendValue, setCommendValue] = useState<CommendValue>(null);
    const [topic, setTopic] = useState("");
    const [comment, setComment] = useState("");
    const [details, setDetails] = useState("");

    const topicInvalid = topic.length > 0 && (topic.length < 3 || topic.length > 100);
    const commentInvalid = comment.length > 1000;
    const detailsInvalid = details.length > 1000;
    const commendValueInvalid = commendValue?.id == null;
    return (
        <Panel collapsed={true}
               ref={ref}
               headerTemplate={
                   <div className="p-panel-header"
                        style={{
                            cursor: "pointer",
                            fontSize: "1.25rem",
                            fontWeight: "bold",
                            background: "linear-gradient(170deg, rgb(241 255 254), transparent)",
                            letterSpacing: "0.1rem"
                        }}
                        onClick={() => ref?.current?.toggle()}>💌️ Отправить Спасибо...</div>}
               toggleable>
            <div className="commends-create">
                <div className="input">
                    <SendCommendBadgeDropdown
                        setCommendValue={setCommendValue}
                        commendValue={commendValue}
                        disabled={disabled}/>
                    <div className="item">
                        <label htmlFor="topic">Тема</label>
                        <InputText id="topic"
                                   value={topic}
                                   onChange={(e) => setTopic(e.target.value)}
                                   disabled={disabled}
                                   invalid={topicInvalid}
                                   placeholder="3 - 100 символов"/>
                    </div>
                    <div className="item">
                        <label htmlFor="comment">Всё самое лучшее</label>
                        <InputTextarea id="comment"
                                       value={comment}
                                       rows={2}
                                       onChange={(e) => setComment(e.target.value)}
                                       disabled={disabled}
                                       invalid={commentInvalid}
                                       placeholder="<1000 символов"/>
                    </div>
                </div>
                <Button label="Отправить"
                        disabled={id == null || commendValueInvalid || detailsInvalid || commentInvalid || topicInvalid || disabled}
                        onClick={() => {
                            setDisabled(true);
                            appContext.axiosWrapper.post("/api/commends/create",
                                {
                                    receiverId: id,
                                    valueId: commendValue.id,
                                    topic: topic,
                                    comment: comment
                                })
                                .then(() => {
                                    appContext.toast.current.show({
                                        severity: 'success',
                                        summary: 'Успешно',
                                        detail: 'Отправлено!',
                                        life: 5000
                                    });
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1000)
                                })
                                .catch((e) => {
                                    setTimeout(() => setDisabled(false), 1000);
                                    throw e;
                                });
                        }}/>
            </div>
        </Panel>
    )
}