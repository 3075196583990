import React, {useContext} from "react";
import {AppContext} from "../app/AppContext";
import {Button} from "primereact/button";
import {useQuery} from "react-query";

interface CommendValueDivProps {
    id: string,
    count?: number
}

export function CommendValueDiv(props: CommendValueDivProps) {
    const appContext = useContext(AppContext);

    const valuesPath = '/api/commends/values';
    const valuesQuery = useQuery(
        valuesPath,
        async () => (await appContext.axiosWrapper.get(valuesPath)).data,
        {
            enabled: appContext.currentPerson != null,
            staleTime: Infinity
        });
    const commendValues = valuesQuery?.data as Array<CommendValue>;

    const commend = commendValues?.find(c => c.id === props.id);

    return (
        <Button outlined
                severity="secondary"
                tooltip={commend?.description}
                tooltipOptions={{showDelay: 150, hideDelay: 100}}
                className="view-commendvalue">{commend?.icon} {commend?.name}{props.count != null ? ": " + props.count : ""}</Button>
    )
}