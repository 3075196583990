import React, {useContext} from "react";
import {useQuery} from "react-query";
import {Panel} from "primereact/panel";
import {ProfileGroups} from "./ProfileGroups";
import {Avatar} from "primereact/avatar";
import {AppContext} from "../app/AppContext";

interface ProfileDetailsProps {
    id: string
}

export function ProfileDetails(props: ProfileDetailsProps) {
    const appContext = useContext(AppContext);
    const queryPath = `/api/profile/data/${props.id}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props.id != null
        });
    const response = (query?.data ?? {}) as GetPersonProfileResponse;

    const position = response?.person?.position
        ? `${response?.person?.position}`
        : "";

    return (
        <Panel header="Профиль коллеги">
            <div className="profile-details">
                <div className="top">
                    <Avatar label={response?.person?.fullName?.at(0)} size="normal" shape="circle"/>
                    <div className="text">
                        <div className="name">{response?.person?.fullName}</div>
                        <div className="position">{position}</div>
                    </div>
                </div>
                <ProfileGroups id={props.id}/>
            </div>
        </Panel>
    )
}