import React from "react";
import {Button} from "primereact/button";
import axios from "axios";
import {useQueryClient} from "react-query";

export function AppTutorial() {
    let queryClient = useQueryClient();
    return (
        <div className="tutorial">
            <h2>Добро пожаловать в <em>Спасибо, команда!</em></h2>
            <p>
                <em>Спасибо, команда!</em> — это веб-приложение, посвященное чествованию командной работы и благодарности на рабочем месте!
            </p>
            <h3>Начало работы</h3>
            <p>После входа в систему вы увидите основную панель с несколькими ключевыми функциями:</p>
            <ul>
                <li><strong>Меню в верхней части:</strong> Доступ ко всем основным функциям через верхнюю панель. Здесь вы найдете
                    опции для просмотра публичной ленты, перехода к вашему профилю и поиска других пользователей.
                </li>
                <li><strong>Публичная лента:</strong> Здесь видны все сообщения с благодарностями, создавая непрерывный поток
                    признания по всей компании. Регулярно проверяйте ее, чтобы видеть и отмечать вклад коллег!
                </li>
                <li><strong>Личные профили:</strong> У каждого пользователя есть профиль, где отображаются все полученные благодарности.
                    Вы можете посетить профиль любого человека, чтобы увидеть его вклад и поздравить его.
                </li>
                <li><strong>Чтобы выразить благодарность конкретному человеку:</strong> Используйте функцию <em>Поиск</em> в верхнем меню,
                    чтобы найти его профиль. В его профиле просто нажмите <strong>Отправить благодарность</strong>, чтобы написать и
                    опубликовать ваше сообщение благодарности. Ваше сообщение появится как в его ленте, так и в публичной ленте.
                </li>
            </ul>
            <Button label="Больше не показывать обучение"
                    onClick={() => {
                        axios.post("/api/settings", {disableTutorial: true})
                            .finally(() => {
                                queryClient.invalidateQueries('/api/settings');
                            })
                    }}/>
        </div>
    )
}