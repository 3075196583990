import React, {useContext} from "react";
import {AppContext} from "../app/AppContext";
import {useParams} from "react-router-dom";
import {GroupProfileDetails} from "./GroupProfileDetails";
import {GroupProfileCommends} from "./GroupProfileCommends";
import {Feed} from "../feed/Feed";
import {GroupProfileMembers} from "./GroupProfileMembers";

export function GroupProfile() {
    const {id} = useParams();
    const appContext = useContext(AppContext);

    const groupId = id?.length > 0 ? id : appContext?.currentPerson?.id;

    return (
        <div className="profile">
            <GroupProfileDetails id={groupId}/>
            <GroupProfileMembers id={groupId}/>
            <GroupProfileCommends id={groupId}/>
            <Feed id={groupId} type='group'/>
        </div>
    )
}