import React, {useContext} from "react";
import {useQuery} from "react-query";
import {AppContext} from "../app/AppContext";
import {Panel} from "primereact/panel";
import {CommendValueDiv} from "../view/CommendValueDiv";

interface GroupProfileCommendsProps {
    id: string
}

export function GroupProfileCommends(props: GroupProfileCommendsProps) {
    const appContext = useContext(AppContext);
    const queryPath = `/api/commends/group/${props.id}`;
    const query = useQuery(
        queryPath,
        async () => (await appContext.axiosWrapper.get(queryPath)).data,
        {
            enabled: appContext.currentPerson != null && props.id != null
        });
    const response = (query?.data ?? {}) as GetCommendInfoResponse;

    return (
        <Panel header="Статистика">
            <div className="group-profile-commends">
                <h3>Всего значков: {response?.total}</h3>
                <div className="types">
                    {response?.byType?.map((c: CountCommendsByTypeProjection) =>
                        <CommendValueDiv key={c.valueId} id={c.valueId} count={c.count}/>)}
                </div>
            </div>
        </Panel>
    )
}