import React, {useContext} from "react";
import {AppContext} from "../app/AppContext";
import {useParams} from "react-router-dom";
import {ProfileCommends} from "./ProfileCommends";
import {ProfileDetails} from "./ProfileDetails";
import {SendCommend} from "../send/SendCommend";

export function Profile() {
    const {id} = useParams();
    const appContext = useContext(AppContext);

    const personId = id?.length > 0 ? id : appContext?.currentPerson?.id;
    const isMyProfile = personId === appContext?.currentPerson?.id;

    return (
        <div className="profile">
            <ProfileDetails id={personId}/>
            {!isMyProfile && <SendCommend/>}
            <ProfileCommends id={personId}/>
        </div>
    )
}