import React, {useState} from "react";
import {Panel} from "primereact/panel";
import {SelectButton} from "primereact/selectbutton";
import {SearchPersonTable} from "./SearchPersonTable";
import {SearchGroupTable} from "./SearchGroupTable";

const enum SearchTypes {
    PERSON,
    GROUP
}

export function Search() {
    const [type, setType] = useState(SearchTypes.PERSON);

    return (
        <Panel style={{width: "100%"}} header="Поиск">
            <div className="search">
                <div className="select">
                    <SelectButton value={type}
                                  onChange={ev => setType(ev.value)}
                                  optionLabel="label"
                                  optionValue="value"
                                  allowEmpty={false}
                                  options={[{value: SearchTypes.PERSON, label: "Коллега"},
                                      {value: SearchTypes.GROUP, label: "Отдел"}]}/>
                </div>
                {type == SearchTypes.GROUP && <SearchGroupTable/>}
                {type == SearchTypes.PERSON && <SearchPersonTable/>}
            </div>
        </Panel>
    )
}